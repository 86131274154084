import React, { useEffect, useState } from 'react';
import Btn from '../../../atom/Btn/Btn';
import Title from '../../../atom/Title/Title';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const NicePaySubscription = (props) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDateYear, setExpiryDateYear] = useState('');
    const [expiryDateMonth, setExpiryDateMonth] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [pw, setPW] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const user_id = localStorage.getItem('id');
    const navigate = useNavigate();

    const handleSubscription = async () => {
        if (!isAgreed) {
            alert('구매 진행에 동의해야 합니다.');
            return;
        }

        const resRegist = await axios.post('/user/subscribe/regist', {
                user_id: user_id,
                card_no: cardNumber,
                exp_year: expiryDateYear,
                exp_month: expiryDateMonth,
                id_no: expiryDateMonth,
                card_pw: pw,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                }
            });

        console.log('resRegist', resRegist);
        if (resRegist?.data?.code !== '0000') {
            alert('카드 정보 등록에 실패했습니다. 담당자에게 문의해주세요!');
            return;
        }

        // 성공
        const resPayment = await axios.post('/user/subscribe/item_set', {
                user_id: user_id,
                payment_id: props.choice == 'basic' ? 1 : 2
            },
            {
                headers: {
                    "Content-Type": "application/json",
                }
            });
        console.log('resPayment', resPayment);
        if (resRegist?.data?.code !== '0000') {
            alert('결제 종류 설정에 실패하였습니다. 담당자에게 문의해주세요!');
            return;
        }

        // 성공
        if (window.confirm('카드 정보가 등록되었습니다. 결제를 진행하시겠습니까?')) {
            const resBill = await axios.post('/user/subscribe/payments', {
                    user_id: user_id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
            console.log('resBill', resBill);

            if (resRegist?.data?.code !== '0000') {
                alert('결제 진행중 실패하였습니다. 담당자에게 문의해주세요!');
            } else {
                alert('결제가 성공적으로 진행되었습니다!');

                navigate('/');
                window.location.reload();
            }
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
                <label style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Title className="light" value='카드 번호*'/>
                    <input
                        style={{minWidth: '100%'}}
                        type="text"
                        value={cardNumber}
                        maxLength={16}
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                </label>
            </div>
            <div className='mt-2'>
                <label style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Title className="light" value='만료 년(YY)*'/>
                    <input
                        type="text"
                        value={expiryDateYear}
                        maxLength={2}
                        onChange={(e) => setExpiryDateYear(e.target.value)}
                    />
                </label>
            </div>
            <div className='mt-2'>
                <label style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Title className="light" value='만료 월(MM)*'/>
                    <input
                        type="text"
                        value={expiryDateMonth}
                        maxLength={2}
                        onChange={(e) => setExpiryDateMonth(e.target.value)}
                    />
                </label>
            </div>
            <div className='mt-2'>
                <label style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Title className="light" value='비밀번호 앞2자리*'/>
                    <input
                        type="text"
                        value={pw}
                        maxLength={2}
                        onChange={(e) => setPW(e.target.value)}
                    />
                </label>
            </div>
            <div className='mt-2'>
                <label style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Title className="light" value='생년월일 6자리 또는 사업자등록번호*'/>
                    <input
                        style={{minWidth: '80%'}}
                        type="text"
                        value={birthDate}
                        maxLength={13}
                        onChange={(e) => setBirthDate(e.target.value)}
                    />
                </label>
            </div>
            <div className='mt-2'>
                <label style={{display: 'flex'}}>
                    <input
                        type="checkbox"
                        checked={isAgreed}
                        onChange={(e) => setIsAgreed(e.target.checked)}
                    />
                    <Title className="light" value='구매 진행에 동의합니다.*'/>
                </label>
            </div>
            <Btn onClick={handleSubscription} variant="primary" size="lg" type="submit"
                 className={`full ${props.choice == 'basic' ? 'pinegreen' : 'lightsalmon'} mt-4 mb-3`} value="결제하기"/>
            <div id="token-result"></div>
        </div>
    );
};

export default NicePaySubscription;
